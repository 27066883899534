import { CardWithImageAndPill } from "app/components/basic/Card";
import Pill, { PillType } from "app/components/basic/Pill";
import Segment from "app/components/page/Segment";
import { Grid } from "semantic-ui-react";
import { IProps } from "app/modules/common/type";
import translations from "app/assets/files/key-value-translations/homepage.json";

export const LatestNews = (props: IProps): JSX.Element => {
	const renderButton = <Pill text={"Campaign"} type={PillType.neutral} isTag={false} />;
	return (
		<Segment className="landing-page-bottom-margin">
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={12} className="no-margin">
						<h4 className="index__intro-title semi-bold">{"Latest news"}</h4>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={6} className="index__card">
						<CardWithImageAndPill
							id="plan-your-legacy-today"
							header=	{translations[props.language].homepagePlanYourLegacyToday.data}
							description=	{translations[props.language].homepagePlanYourLegacyTodayDescription.data}
							image="/images/plan-your-legacy-today-banner.png"
							imageAltText={"plan-your-legacy-today"}
							href="https://www.planyourlegacy.gov.sg/"
							buttonBelowImage={renderButton}
							PillText="Campaign"
							className="link-full-width-mobile"
						/>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={12} computer={6} className="index__card"></Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default LatestNews;
